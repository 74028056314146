import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { logException } from 'sentry'
import { onError } from 'lp-hoc'
import logo from 'images/logo.svg'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function Layout ({ children }) {
  return (
    <div>
      <img className="logo" src={ logo } alt="Lwala Annual Report 2018" />
      { children }
    </div>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function onComponentDidCatch (props, error, errorInfo) {
  return logException(error, errorInfo)
}

export default compose(
  onError(onComponentDidCatch),
)(Layout)
