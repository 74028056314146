const LWALA_DATA_VIZ = 'lwala-data-viz'
// sessionStorage to expire cache on leaving session, but maybe just want localStorage

export function getLwalaData () {
  const json = localStorage.getItem(LWALA_DATA_VIZ)
  return json ? JSON.parse(json) : {}
}

export function setLwalaData (dataObj) {
  return localStorage.setItem(LWALA_DATA_VIZ, JSON.stringify(dataObj))
}
