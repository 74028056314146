import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
// import { onMount } from 'lp-hoc'
import { FullChart } from '../views'
// import * as firebaseSvc from 'firebase-service'
// import * as actions from '../actions'

const propTypes = {
  lwalaData: PropTypes.object.isRequired,
}

const defaultProps = {}

function Home ({
  // can refactor and map later
  lwalaData: { liveBirths, coupleYearsProtection, childVaccines, communityProtection, contraceptiveMethods }
}) {
  return (
    <div>
      <FullChart
        chartData={ coupleYearsProtection }
      />
      <FullChart
        chartData={ liveBirths }
      />
      <FullChart
        chartData={ communityProtection }
      />
      <FullChart
        chartData={ childVaccines }
      />
      <FullChart
        chartData={ contraceptiveMethods }
      />
      <FullChart
        chartData={ childVaccines }
      />
      <FullChart
        chartData={ communityProtection }
      />
      <FullChart
        chartData={ contraceptiveMethods }
      />
    </div>
  )
}

Home.propTypes = propTypes

Home.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    lwalaData: selectors.lwalaData(state)
  }
}

const mapDispatchToProps = {
  // lwalaData: actions.fetchData
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  // onMount(firebaseSvc.fetchCurrentSession)
)(Home)
