export const initialLwalaData = {
  liveBirths: {
    category: 'Maternal Health',
    title: 'PERCENTAGE OF LIVE BIRTHS DELIVERED BY A SKILLED PROVIDER',
    chartType: 'SimpleBarChart',
    imageName: 'liveBirthsImage',
    target: 0.98,
    data: [
      { name: '2010', north: 0.26 },
      { name: '2011', north: 0.47 },
      { name: '2012', north: 0.92 },
      { name: '2013', north: 0.96 },
      { name: '2014', north: 0.94 },
      { name: '2015', north: 0.97 },
      { name: '2016', north: 0.97 },
      { name: '2017', north: 0.97 },
      { name: '2018', north: 0.96 }
    ],
    context: "We exceeded our target (96% of ~600 births) again in 2017. Comparison facility data comes from unpublished DHIS data (Audet et al.); county average comes from the Kenya Demographic and Health Survey 2014. The metric is based off a WHO metric. Source: Public Health Scorecard"
  },
  coupleYearsProtection: {
    category: 'Family Planning',
    title: "Couple Years' Protection",
    chartType: 'StackedBarChart',
    imageName: 'contraceptiveMethodsImage',
    target: 14000,
    data: [
      { name: 'JAN', north: 740, east: 740 },
      { name: 'FEB', north: 776, east: 776 },
      { name: 'MAR', north: 854, east: 854 },
      { name: 'APR', north: 672, east: 672 },
      { name: 'MAY', north: 869, east: 869 },
      { name: 'JUN', north: 579, east: 579 },
      { name: 'JUL', north: 599, east: 599 },
      { name: 'AUG', north: 862, east: 862 },
      { name: 'SEP', north: 804, east: 804 },
      { name: 'OCT', north: 964, east: 964 },
      { name: 'NOV', north: 783, east: 783 },
      { name: 'DEC', north: 789, east: 789 }
    ],
    context: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nec tortor erat. Morbi posuere mauris nec sollicitudin consequat. Proin non eros dui. Quisque augue dolor, auctor eget est a, viverra maximus dui. Phasellus quam lorem, maximus et diam a, molestie bibendum quam. Etiam turpis nisl, feugiat eget nulla id, gravida lobortis orci. Nulla vel tincidunt orci. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus tincidunt leo in porta tincidunt. Maecenas vehicula mauris risus, et elementum libero consectetur in."
  },
  childVaccines: {
    category: 'Child Health',
    title: 'Percent of Children Who Received All Specified Vaccines',
    chartType: 'SimpleBarChart',
    imageName: 'infantMortalityRatesImage',
    target: 1.0,
    data: [
      { name: '2014', north: 0.57 },
      { name: '2016', north: 0.94 },
      { name: '2017', north: 0.96 },
      { name: '2018', north: 0.96 }
    ],
    context: "We exceeded our target (96% of ~600 births) again in 2017. Comparison facility data comes from unpublished DHIS data (Audet et al.); county average comes from the Kenya Demographic and Health Survey 2014. The metric is based off a WHO metric. Source: Public Health Scorecard"
  },
  communityProtection: {
    category: 'Family Planning',
    title: "Couple Years Protection Provided by Community",
    chartType: 'StackedBarChart',
    imageName: 'contraceptiveMethodsImage',
    data: [
      { name: '2013', north: 3752, east: 0 },
      { name: '2014', north: 3477, east: 0 },
      { name: '2015', north: 5771, east: 0 },
      { name: '2016', north: 9575, east: 38 },
      { name: '2017', north: 1491, east: 305 }
    ],
    context: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nec tortor erat. Morbi posuere mauris nec sollicitudin consequat. Proin non eros dui. Quisque augue dolor, auctor eget est a, viverra maximus dui. Phasellus quam lorem, maximus et diam a, molestie bibendum quam. Etiam turpis nisl, feugiat eget nulla id, gravida lobortis orci. Nulla vel tincidunt orci. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus tincidunt leo in porta tincidunt. Maecenas vehicula mauris risus, et elementum libero consectetur in."
  },
  contraceptiveMethods: {
    category: 'Family Planning',
    title: 'Contraceptive Methods, North KamaGambo',
    chartType: 'SimpleLineChart',
    imageName: 'contraceptiveMethodsImage',
    data: [
      { name: 'JAN', IUCD: 71, Implants: 171, Injections: 135, Pills: 15 },
      { name: 'FEB', IUCD: 84, Implants: 144, Injections: 142, Pills: 17 },
      { name: 'MAR', IUCD: 94, Implants: 198, Injections: 144, Pills: 14 },
      { name: 'APR', IUCD: 80, Implants: 162, Injections: 122, Pills: 1 },
      { name: 'MAY', IUCD: 77, Implants: 186, Injections: 168, Pills: 6 },
      { name: 'JUN', IUCD: 48, Implants: 137, Injections: 138, Pills: 21 },
      { name: 'JUL', IUCD: 43, Implants: 139, Injections: 132, Pills: 15 },
      { name: 'AUG', IUCD: 71, Implants: 199, Injections: 174, Pills: 26 },
      { name: 'SEP', IUCD: 38, Implants: 188, Injections: 104, Pills: 16 },
      { name: 'OCT', IUCD: 43, Implants: 253, Injections: 88,  Pills: 19 },
      { name: 'NOV', IUCD: 36, Implants: 231, Injections: 125, Pills: 25 },
      { name: 'DEC', IUCD: 45, Implants: 200, Injections: 128, Pills: 19 },
    ],
    context: "Couple-years protection (CYP) increased steadily throughout 2016 and 2017. This is due to an increasing uptake of high-CYP interventions, including intrauterine contraceptive devices and implants. A total of 3,519 family planning interventions were given out in 2016 for a total of 5,771 couple-years protection. We exceeded that number in 2017 with a total distribution of 4,732 contraceptive interventions. Source: Clinical Records"
  }
}
