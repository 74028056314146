import React from 'react'
import PropTypes from 'prop-types'
import {
  LineChart,
  Line,
  ReferenceLine,
  XAxis,
  CartesianGrid,
  Legend
} from 'recharts'

const propTypes = {
  data: PropTypes.array,
  target: PropTypes.number,
}

const defaultProps = {}

function SimpleLineChart ({ data, target }) {
  return (
    <div>
      <LineChart
        width={ 600 }
        height={ 450 }
        data={ data }
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="0" stroke="#5EA292" vertical={ false } />
        <XAxis tick={{ fill: '#FFFFFF' }} dataKey="name"/>
        <Legend iconType={ "square" }/>
        <ReferenceLine y={ target } stroke="red"/>
        <Line type="monotone" dataKey="IUCD" stroke="#82D3F2" activeDot={{r: 8}}/>
        <Line type="monotone" dataKey="Implants" stroke="#75DDC5" />
        <Line type="monotone" dataKey="Injections" stroke="#262626" />
        <Line type="monotone" dataKey="Pills" stroke="#A8A942" />
      </LineChart>
    </div>
  )
}

SimpleLineChart.propTypes = propTypes

SimpleLineChart.defaultProps = defaultProps

export default SimpleLineChart
