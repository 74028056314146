import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  context: PropTypes.string,
}

const defaultProps = {}

function Context ({ context }) {
  return (
    <div>
     <p>{ context }</p>
    </div>
  )
}

Context.propTypes = propTypes

Context.defaultProps = defaultProps

export default Context
