import React from 'react'
import PropTypes from 'prop-types'
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  ReferenceLine,
  LabelList
} from 'recharts'

const propTypes = {
  data: PropTypes.array,
  target: PropTypes.number,
}

const defaultProps = {}

function StackedBarChart ({ data, target }) {
  return (
    <div>
      <BarChart width={ 600 } height={ 450 } data={ data } barSize={ 30 } barGap={ 20 }>
        <CartesianGrid strokeDasharray="0" stroke="#5EA292" vertical={ false } />
        <XAxis tick={{ fill: '#FFFFFF' }} dataKey="name" />
        <ReferenceLine y={ target } stroke="red" />
        <Bar dataKey="north" stackId="a" fill="#A8A942">
          <LabelList dataKey="north" position="inside" />
        </Bar>
        <Bar dataKey="east" stackId="a" fill="#262626">
          <LabelList dataKey="east" position="inside" fill="#fff" />
        </Bar>
      </BarChart>
    </div>
  )
}

StackedBarChart.propTypes = propTypes

StackedBarChart.defaultProps = defaultProps

export default StackedBarChart
