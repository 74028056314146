import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  category: PropTypes.string,
  title: PropTypes.string,
}

const defaultProps = {}

function ChartHeader ({ category, title }) {
  return (
    <div>
      <h1 className="category"> { category } </h1>
      <h3 className="title"> { title } </h3>
    </div>
  )
}

ChartHeader.propTypes = propTypes

ChartHeader.defaultProps = defaultProps

export default ChartHeader
