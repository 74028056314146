import React from 'react'
import PropTypes from 'prop-types'
// import { compose } from 'redux'
// import { connect } from 'react-redux'
import {
  SimpleBarChart,
  StackedBarChart,
  SimpleLineChart,
  Context,
  ChartHeader,
} from '../components'
import { Section } from 'react-fullpage'
import liveBirthsImage from 'images/live-births.jpg'
import contraceptiveMethodsImage from 'images/contraceptive-methods.jpg'
import infantMortalityRatesImage from 'images/infant-mortality-rates.jpg'
import totalCHWImage from 'images/total-chw.jpg'

const propTypes = {
  chartData: PropTypes.object,
}

const chartTypes = {
  'SimpleBarChart': SimpleBarChart,
  'StackedBarChart': StackedBarChart,
  'SimpleLineChart': SimpleLineChart,
}

const images = {
  'liveBirthsImage': liveBirthsImage,
  'contraceptiveMethodsImage': contraceptiveMethodsImage,
  'infantMortalityRatesImage': infantMortalityRatesImage,
  'totalCHWImage': totalCHWImage
}

const defaultProps = {}

function FullChart ({
  chartData: { category, title, imageName, chartType, target, data, context }
}) {
  let ChartType = chartTypes[chartType]
  return (
    <div className="container" style={{ backgroundImage: `url(${ images[imageName] })` }}>
      <Section>
        <div className="center-content">
          <div className="left-content">
            <ChartHeader
              category={ category }
              title={ title }
            />
            <Context
              context={ context }
            />
          </div>
          <ChartType
            data={ data }
            target={ target }
          />
        </div>
      </Section>
    </div>
  )
}

FullChart.propTypes = propTypes

FullChart.defaultProps = defaultProps

// function mapStateToProps(state) {}

// const mapDispatchToProps = {}

export default FullChart
// compose(
//   connect(mapStateToProps, mapDispatchToProps)
// )(FullChart)
