import * as firebase from 'firebase'
import * as storage from 'local-storage'
import { logException } from 'sentry'

const {
  FIREBASE_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_AUTH_EMAIL,
  FIREBASE_AUTH_PASSWORD,
} = process.env


let firebaseDb = null

const FIREBASE_CONFIG = {
  apiKey: FIREBASE_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
}

function initializeFirebase () {
  return firebase.initializeApp(FIREBASE_CONFIG)
}

function signInUser () {
  if (firebase.auth().currentUser) return
  return firebase
    .auth()
    .signInWithEmailAndPassword(FIREBASE_AUTH_EMAIL, FIREBASE_AUTH_PASSWORD)
    .then(() => firebaseDb = firebase.database())
    .catch(error => logException(error))
}

initializeFirebase()
signInUser()

export function getFirebaseDb() {
  if (!firebase.auth().currentUser) return false
  return firebaseDb
}

// do  I need this?
export function setSession (params) {
  // is setSession specific, should i call it setData?
  if (!firebaseDb) return
  const lwalaDataRef = firebaseDb.ref('lwala-data')
  const newLwalaData = lwalaDataRef.push({ ...params })
  return newLwalaData.key
}

export function fetchCurrentSession () {
  // maybe this is all I need? (and call it fetchData?)
  if (!firebaseDb) return
  const lwalaData = storage.getLwalaData()
  if (!lwalaData) return
  return firebaseDb
    .ref('lwala-data')
    .catch(error => logException(error)) // can / should I have this here?
}

export function closeFirebaseConnection() {
  if(firebase.auth().currentUser) firebase.auth().signOut()
  if(!firebaseDb) return
  return firebaseDb.goOffline()
}
