import { handleActions } from 'redux-actions'
import { selectorForSlice } from 'lp-redux-utils'
import { initialLwalaData } from 'initial-data'
import * as storage from 'local-storage'
// import * as firebaseService from 'firebase-service'

const reducerKey = 'home'
const slice = 'root.home'

storage.setLwalaData(initialLwalaData)
// is "storage" firebase in prodcuction?

const lwalaData = storage.getLwalaData()
const initialState = { lwalaData: lwalaData }

const reducer = handleActions({}, initialState)

const select = selectorForSlice(slice)

const selectors = {
  lwalaData: select('lwalaData', initialState)
}

export { reducer, selectors, reducerKey }
